<template>
  <div class="container-main-content signup">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-4">Đăng ký</h4>
        <div class="content-inner__body-form">
          <ValidationObserver ref="formSignup">
            <form @keyup.enter="handleSignup">
              <ValidationProvider
                name="Tên đăng nhập"
                rules="required|min3"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    type="text"
                    v-model.trim="form.username"
                    class="form-control"
                    id="floatingUsername"
                    placeholder="name@example.com"
                  />
                  <label for="floatingUsername">Tên đăng nhập</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="Địa chỉ email"
                rules="required|min3|email"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    type="text"
                    v-model.trim="form.email"
                    class="form-control"
                    id="floatingEmail"
                    placeholder="name@example.com"
                  />
                  <label for="floatingEmail">Địa chỉ email</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="Số điện thoại"
                rules=""
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    type="text"
                    v-model.trim="form.phone"
                    class="form-control"
                    id="floatingPhone"
                    placeholder="name@example.com"
                  />
                  <label for="floatingPhone">Số điện thoại</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="Mật khẩu"
                rules="required|min3"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    type="password"
                    v-model.trim="form.password"
                    class="form-control"
                    id="floatingPassword"
                    placeholder="name@example.com"
                  />
                  <label for="floatingPassword">Mật khẩu</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="Xác nhận mật khẩu"
                rules="required|password:@Mật khẩu"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    type="password"
                    v-model.trim="form.passwordConfirm"
                    class="form-control"
                    id="floatingPasswordConfirm"
                    placeholder="name@example.com"
                  />
                  <label for="floatingPasswordConfirm">Xác nhận mật khẩu</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </form>
          </ValidationObserver>
        </div>
        <button
          type="button"
          class="btn btn-primary mb-4"
          @click="handleSignup"
        >
          TIẾP TỤC
        </button>
        <p class="content-inner__body-start f14 mt-4">
          Đã có tài khoản?
          <a href="#" class="link-primary-color" @click.prevent="handleLogin">
            Đăng nhập
          </a>
        </p>
      </div>
      <Footer />
    </div>

    <Loading
      :active="loading.isLoading"
      :can-cancel="true"
      :is-full-page="loading.fullPage"
      color="#3E69F6"
    >
    </Loading>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { UserService } from "../../services/user.service";

export default {
  name: "Signup",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "",
      form: {
        username: "",
        email: "",
        phone: "",
        password: "",
        passwordConfirm: "",
      },
      userId: "",
      loading: {
        isLoading: false,
        fullPage: true,
      },
    };
  },
  methods: {
    handleSignup() {
      this.$refs.formSignup.validate().then(async (success) => {
        if (success) {
          try {
            this.loading.isLoading = true;
            const { username, email, phone, password } = this.form;
            const dataSubmit = { username, email, phone, password };
            const response = await UserService.createUser(dataSubmit);
            if (response.code === "SUCCESS") {
              this.userId = response.data.userId;
              if (this.currentQueries) {
                this.$router.push({
                  path: `/signup-mail/${this.userId}${this.currentQueries}&email=${this.form.email}`,
                });
              } else {
                this.$router.push({
                  path: `/signup-mail/${this.userId}?email=${this.form.email}`,
                });
              }
            } else {
              if(response.data.code === 'EMAIL_ALREADY_EXIST'){
                alert('Địa chỉ email của bạn đã tồn tại');
              }else{
                alert(response.data.code);
              }
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.loading.isLoading = false;
          }
        }
      });
    },
    handleLogin() {
      this.$router.push({
        path: `/login${this.currentQueries}`,
      });
    },
  },
};
</script>
