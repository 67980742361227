var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-main-content signup"},[_c('div',{staticClass:"content-inner"},[_c('Header'),_c('div',{staticClass:"content-inner__body"},[_c('h4',{staticClass:"content-inner__body-title mb-4"},[_vm._v("Đăng ký")]),_c('div',{staticClass:"content-inner__body-form"},[_c('ValidationObserver',{ref:"formSignup"},[_c('form',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSignup.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"Tên đăng nhập","rules":"required|min3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-floating mb-4"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.username),expression:"form.username",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","id":"floatingUsername","placeholder":"name@example.com"},domProps:{"value":(_vm.form.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "username", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{attrs:{"for":"floatingUsername"}},[_vm._v("Tên đăng nhập")]),_c('p',{staticClass:"text-danger f12 mt-1"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{attrs:{"name":"Địa chỉ email","rules":"required|min3|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-floating mb-4"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.email),expression:"form.email",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","id":"floatingEmail","placeholder":"name@example.com"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{attrs:{"for":"floatingEmail"}},[_vm._v("Địa chỉ email")]),_c('p',{staticClass:"text-danger f12 mt-1"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{attrs:{"name":"Số điện thoại","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-floating mb-4"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.phone),expression:"form.phone",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","id":"floatingPhone","placeholder":"name@example.com"},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phone", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{attrs:{"for":"floatingPhone"}},[_vm._v("Số điện thoại")]),_c('p',{staticClass:"text-danger f12 mt-1"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{attrs:{"name":"Mật khẩu","rules":"required|min3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-floating mb-4"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.password),expression:"form.password",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"password","id":"floatingPassword","placeholder":"name@example.com"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{attrs:{"for":"floatingPassword"}},[_vm._v("Mật khẩu")]),_c('p',{staticClass:"text-danger f12 mt-1"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{attrs:{"name":"Xác nhận mật khẩu","rules":"required|password:@Mật khẩu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-floating mb-4"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.passwordConfirm),expression:"form.passwordConfirm",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"password","id":"floatingPasswordConfirm","placeholder":"name@example.com"},domProps:{"value":(_vm.form.passwordConfirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "passwordConfirm", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{attrs:{"for":"floatingPasswordConfirm"}},[_vm._v("Xác nhận mật khẩu")]),_c('p',{staticClass:"text-danger f12 mt-1"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)])],1),_c('button',{staticClass:"btn btn-primary mb-4",attrs:{"type":"button"},on:{"click":_vm.handleSignup}},[_vm._v(" TIẾP TỤC ")]),_c('p',{staticClass:"content-inner__body-start f14 mt-4"},[_vm._v(" Đã có tài khoản? "),_c('a',{staticClass:"link-primary-color",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleLogin.apply(null, arguments)}}},[_vm._v(" Đăng nhập ")])])]),_c('Footer')],1),_c('Loading',{attrs:{"active":_vm.loading.isLoading,"can-cancel":true,"is-full-page":_vm.loading.fullPage,"color":"#3E69F6"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }